"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function NoopWrapper(props) {
  return props.children;
}
var _default = NoopWrapper;
exports.default = _default;